import defalt_setting from "./defalt_setting";

const gameoversettings = document.getElementById('over-settings-button');
// const startsetitngs = document.getElementById('start-settings-button');
const close = document.getElementById('close');
const modal = document.getElementById('modal');
const mask = document.getElementById('mask');
const sensi = document.getElementById("sensi");
const duaration = document.getElementById("duaration");
const sensival = document.getElementById("sensival");
const duarationval = document.getElementById("duarationval");
const reset = document.getElementById("reset_button");
let issetting = false;

sensi.value = localStorage.getItem("sensi") ? parseFloat(localStorage.getItem("sensi")) : defalt_setting.sensi;
duaration.value = localStorage.getItem("duaration") ? parseFloat(localStorage.getItem("duaration")) : defalt_setting.duaration;

gameoversettings.addEventListener('click', function () {
    modal.classList.remove('hidden');
    mask.classList.remove('hidden');
    issetting = true;
});

// startsetitngs.addEventListener('click', function () {
//     modal.classList.remove('hidden');
//     mask.classList.remove('hidden');
// });

close.addEventListener('click', function () {
    localStorage.setItem("sensi", sensi.value);
    localStorage.setItem("duaration", duaration.value);
    modal.classList.add('hidden');
    mask.classList.add('hidden');
    issetting = false;
});
mask.addEventListener('click', function () {
    modal.classList.add('hidden');
    mask.classList.add('hidden');
    issetting = false;
});

sensival.innerText = sensi.value;
sensi.addEventListener("input", () => {
    sensival.innerText = sensi.value;
});

duarationval.innerText = duaration.value;
duaration.addEventListener("input", () => {
    duarationval.innerText = duaration.value;
});

reset.addEventListener("click", () => {
    sensi.value = defalt_setting.sensi;
    duaration.value = defalt_setting.duaration;
    sensival.innerText = sensi.value;
    duarationval.innerText = duaration.value;
});

export default () => (
    {
        sensi: sensi.value,
        duaration: duaration.value,
        issetting: issetting
    }
);