import "./style.css";
import sprites_src from "./util_js/sprite_svg_obj";
import Tweet from "./util_js/tweet";
import setting from "./util_js/setting_modal";
import { Howl } from "howler";
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

const analytics = Analytics({
    app: '台パン鳥',
    plugins: [
        googleAnalytics({
            trackingId: process.env.GA_TAG ? process.env.GA_TAG : 'UA-1234567'
        })
    ]
});

analytics.page();

const jump_sound = new Howl({
    src: "./assets/jump.mp3",
    preload: true
});

const gameover_sound = new Howl({
    src: "./assets/gameover.mp3",
    preload: true
});

const bg = new Image();
bg.src = "data:image/svg+xml," + encodeURIComponent(sprites_src.bgSVG);

const fg = new Image();
fg.src = "data:image/svg+xml," + encodeURIComponent(sprites_src.fgSVG);

const dinoGlide = new Image();
dinoGlide.src = "data:image/svg+xml," + encodeURIComponent(sprites_src.sprite1);

const dinoFlap = new Image();
dinoFlap.src = "data:image/svg+xml," + encodeURIComponent(sprites_src.sprite2);

const topBone = new Image();
topBone.src = "data:image/svg+xml," + encodeURIComponent(sprites_src.topBoneSVG);

const botBone = new Image();
botBone.src = "data:image/svg+xml," + encodeURIComponent(sprites_src.botBoneSVG);

const canvas = document.getElementById("c");
const ctx = canvas.getContext("2d");

const hitboxWidth = 60;
const hitboxHeight = 15;

const spriteOffset_X = -10;
const spriteOffset_Y = -25;

let fgPos_X = 0;

let dinoPos_X = 100;
let dinoPos_Y = 250;
let dinoAngle = 0;
let dinoState = dinoGlide;

const minBoneHeight = -330;
const maxBoneHeight = -160;

const scrollSpeed = -3;
const gravity = 0.3;
let lift = -8;
let velocity = 0;

let start = false;
let gameOver = false;
let score = 0;

let bonePtr = 0;
const boneStart = 400;
const flyGap = 180;
const boneGap = 280;
let jumpcount = 0;
let bones = [
    { x: boneStart, y: getRandomHeight(minBoneHeight, maxBoneHeight) },
    { x: boneStart + boneGap, y: getRandomHeight(minBoneHeight, maxBoneHeight) },
    {
        x: boneStart + 2 * boneGap,
        y: getRandomHeight(minBoneHeight, maxBoneHeight)
    }
];

let highscore = localStorage.getItem("highscore") ? parseFloat(localStorage.getItem("highscore")) : 0;

let prejumptime = 0;
const max_accelation = 10;

const Zbarcanvas = document.getElementById("zbar");
const settingsZbarcanvas = document.getElementById("settingszbar");
let barcolor = {
    r: 143,
    g: 0,
    b: 0
};

// iosではセンサーの正負が逆
let sensor_prefix = 1;

function updateBar(Z_value, barcanvas) {
    const bar = barcanvas.getContext("2d");
    const nograbity_z = Z_value - 9;
    const nograbity_th = setting().sensi - 9;
    const bar_width = nograbity_z / max_accelation * barcanvas.width;
    bar.clearRect(0, 0, barcanvas.width, barcanvas.height);
    if (Z_value > setting().sensi) {
        bar.fillStyle = `rgb(${barcolor.r},${barcolor.g},${barcolor.b})`;
        bar.fillRect(0, 0, bar_width, barcanvas.height);
    } else {
        if (barcolor.g !== 0) {
            barcolor.g = barcolor.g - 4;
        }
        bar.fillStyle = `rgb(${barcolor.r},${barcolor.g},${barcolor.b})`;
        bar.fillRect(0, 0, bar_width, barcanvas.height);
    }
    bar.fillStyle = "#FFFF00";
    bar.fillRect(nograbity_th / max_accelation * barcanvas.width, 0, 2, barcanvas.height);
}


function getRandomHeight(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}

function playerInput() {
    if (!start) {
        analytics.track('gamestart', {
            category: 'gameresult',
            label: 'gamestart',
        });
        start = true;
        velocity = lift;
        dinoAngle = -20;
        document.getElementById("ctrl-ctn").style.opacity = 0;
    } else {
        if (!gameOver) {
            velocity = lift;
            dinoAngle = -20;
        }
    }

    if (!gameOver) {
        jump_sound.play();
        jumpcount++;
    }
}

function checkCollision() {
    // set upper bound
    if (dinoPos_Y <= -hitboxHeight) {
        velocity = 0;
    }

    // dino hit the ground
    if (dinoPos_Y + hitboxHeight >= canvas.height - fg.height) {
        velocity = 0;
        dinoPos_Y = canvas.height - fg.height - hitboxHeight;
        setGameOver();
    }

    // dino hit bone
    if (
        dinoPos_X + hitboxWidth >= bones[bonePtr].x &&
        dinoPos_X < bones[bonePtr].x + topBone.width &&
        (dinoPos_Y <= bones[bonePtr].y + topBone.height ||
            dinoPos_Y + hitboxHeight >= bones[bonePtr].y + topBone.height + flyGap)
    ) {
        setGameOver();
    }
}

function replay() {
    start = false;
    gameOver = false;

    score = 0;
    velocity = 0;

    dinoPos_X = 100;
    dinoPos_Y = 250;
    dinoAngle = 0;
    dinoState = dinoGlide;

    bonePtr = 0;
    bones = [
        { x: boneStart, y: getRandomHeight(minBoneHeight, maxBoneHeight) },
        {
            x: boneStart + boneGap,
            y: getRandomHeight(minBoneHeight, maxBoneHeight)
        },
        {
            x: boneStart + 2 * boneGap,
            y: getRandomHeight(minBoneHeight, maxBoneHeight)
        }
    ];

    document.getElementById("ctrl-ctn").style.opacity = 1;

    document.getElementById("gameover-screen").style.visibility = "hidden";
    document.getElementById("gameover-screen").style.opacity = 0;
}

function setGameOver() {
    if (!gameOver) {
        gameover_sound.play();
        analytics.track('jumpcount', {
            category: 'gameresult',
            label: 'jumpcount',
            value: jumpcount
        });
        if (score > highscore) {
            highscore = score;
            localStorage.setItem("highscore", highscore);
            analytics.track('highscore', {
                category: 'gameresult',
                label: 'highscore',
                value: highscore
            });
        }
        document.getElementById("endscoreboard").innerHTML = `HighScore: ${highscore}<br>Score: ${score}`;
        document.getElementById("gameover-screen").style.visibility = "visible";
        document.getElementById("gameover-screen").style.opacity = 1;
        gameOver = true;
        jumpcount = 0;
    }
}


function update() {
    // foreground scroll
    if (!gameOver) {
        fgPos_X += scrollSpeed;

        if (fgPos_X <= -canvas.width) {
            fgPos_X = 0;
        }
    }

    // game started
    if (start) {
        velocity += gravity;
        dinoPos_Y += velocity;

        // if velocity is negative show dino flap sprite else show dino falling
        if (velocity < 0) {
            dinoState = dinoFlap;
        } else {
            dinoState = dinoGlide;
            dinoAngle = Math.min(dinoAngle + 2, 90);
        }

        checkCollision();

        if (!gameOver) {
            for (var i = 0; i < bones.length; i++) {
                // scroll bones across screen
                bones[i].x += scrollSpeed;

                // reassign bone object XY after it leaves screen
                if (bones[i].x <= -topBone.width) {
                    if (i == 0) {
                        bones[0].x = bones[bones.length - 1].x + boneGap;
                    } else {
                        bones[i].x = bones[i - 1].x + boneGap;
                    }

                    bones[i].y = getRandomHeight(minBoneHeight, maxBoneHeight);
                }
            }

            // if dino passed through the bone increase score
            if (dinoPos_X >= bones[bonePtr].x + topBone.width) {
                score++;

                if (bonePtr == 2) {
                    bonePtr = 0;
                } else {
                    bonePtr++;
                }
            }
        }
    }
    render();
}

function render() {
    document.getElementById("scoreboard").innerHTML = score;

    ctx.drawImage(bg, 0, 0);

    for (var i = 0; i < bones.length; i++) {
        ctx.drawImage(topBone, bones[i].x, bones[i].y);
        ctx.drawImage(botBone, bones[i].x, bones[i].y + topBone.height + flyGap);
    }

    ctx.save();
    ctx.translate(
        dinoPos_X + hitboxWidth / 2 + spriteOffset_X,
        dinoPos_Y + hitboxHeight / 2
    );
    ctx.rotate(dinoAngle * Math.PI / 180);
    ctx.drawImage(
        dinoState,
        -hitboxWidth / 2,
        -hitboxHeight / 2 + spriteOffset_Y
    );
    ctx.restore();

    // Show hitbox
    // ctx.fillStyle = "#ff00ff";
    // ctx.fillRect(dinoPos_X, dinoPos_Y, hitboxWidth, hitboxHeight);

    ctx.drawImage(fg, fgPos_X, canvas.height - fg.height);
    ctx.drawImage(fg, fgPos_X + fg.width, canvas.height - fg.height);

    window.requestAnimationFrame(update);
}

// PC用: スペースキーによるジャンプ
document.addEventListener("keydown", function (e) {
    var char = e.which || e.keyCode;
    if (char == 32 || char == 38) {
        e.preventDefault();
        playerInput();
    }
});

// スマホ用: タップによるジャンプ
// document.addEventListener("touchstart", function () {
//     playerInput();
// });

// replayボタン
document.getElementById("replay").addEventListener("click", function () {
    replay();
});

// 台パン用: ジャンプ
let motion_data = {
    "X": 0,
    "Y": 0,
    "Z": 0
};

document.getElementById("init-screen").style.visibility = "visible";
document.getElementById("init-screen").style.opacity = 1;

function smash_play(dat) {
    motion_data.X = dat.accelerationIncludingGravity.x * sensor_prefix;
    motion_data.Y = dat.accelerationIncludingGravity.y * sensor_prefix;
    motion_data.Z = dat.accelerationIncludingGravity.z * sensor_prefix;
    updateBar(motion_data.Z, Zbarcanvas);
    updateBar(motion_data.Z, settingsZbarcanvas);
    if (!setting().issetting) {
        if (motion_data.Z > setting().sensi && (Date.now() - prejumptime) > setting().duaration && !gameOver) {
            // 既定値を超えた瞬間のみ緑に変える
            barcolor.g = 256;
            playerInput();
            prejumptime = Date.now();
        } else if (motion_data.Z > setting().sensi && (Date.now() - prejumptime) > 2000 && gameOver) {
            barcolor.g = 256;
            replay();
            prejumptime = Date.now();
        }
    }
    // 設定画面用
    else {
        if (motion_data.Z > setting().sensi && (Date.now() - prejumptime) > setting().duaration) {
            // 既定値を超えた瞬間のみ緑に変える
            jump_sound.play();
            barcolor.g = 256;
            prejumptime = Date.now();
        }
    }
}

const requestDeviceMotionPermission = () => {
    if (
        DeviceMotionEvent &&
        typeof DeviceMotionEvent.requestPermission === 'function'
    ) {
        // iOS 13+ の Safari
        // 許可を取得
        DeviceMotionEvent.requestPermission()
            .then(permissionState => {
                if (permissionState === 'granted') {
                    document.getElementById("ctrl-ctn").style.opacity = 1;
                    document.getElementById("init-screen").style.visibility = "hidden";
                    document.getElementById("init-screen").style.opacity = 0;
                    window.addEventListener('devicemotion', smash_play);
                    sensor_prefix = -1;
                } else {
                    window.alert("再読込してデバイスのセンサーの使用を許可してください．");
                }
            })
            .catch(console.error); // https通信でない場合などで許可を取得できなかった場合
    } else {
        document.getElementById("ctrl-ctn").style.opacity = 1;
        document.getElementById("init-screen").style.visibility = "hidden";
        document.getElementById("init-screen").style.opacity = 0;
        window.addEventListener('devicemotion', smash_play);
    }
};


document.getElementById("play-button").addEventListener('click', requestDeviceMotionPermission, false);
document.getElementById("tweet").addEventListener('click', () => {
    analytics.track('tweet', {
        category: 'share',
        label: 'tweetshare'
    });
    Tweet(highscore);
});

update();

